
























































































import enumeracionModule from "@/store/modules/enumeracion-module";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxSortable } from "devextreme-vue/sortable";
import { Component, Prop, Vue } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { pantalla_modo_lista } from "@/shared/dtos/dynamic_tables/pantalla_modo_lista";
import pantalla_modo_listaModule from "@/store/modules/pantalla_modo_lista-module";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { BaseDto } from "@/shared/dtos/base-dto";
@Component({
  components: {
    DxScrollView,
    DxSortable,
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class ViewKanban extends Vue {
  @Prop({ default: 16 }) id_tabla!: number;
  @Prop({ default: 2 }) id_enumeracion!: number;
  @Prop({ default: "id_prioridad" }) nombre_variable!: string;
  @Prop({ default: true }) shwtoolbar!: boolean;
  @Prop({ default: "" }) search!: string;

  public lists: any[] = [];
  public datasource: any[] = [];
  public pantalla_modo_lista: pantalla_modo_lista = new pantalla_modo_lista();

  created() {
    enumeracionModule.getenumeraciones_fromtipo(this.id_enumeracion);
    pantalla_modo_listaModule
      .getpantalla_modo_lista(this.get_id_tabla)
      .then((x: pantalla_modo_lista) => {
        this.pantalla_modo_lista = x;
        ssmHttpService
          .get(API.webApiBase + this.pantalla_modo_lista.pantalla.api_call)
          .then((x) => (this.datasource = x));
      });
  }

  public get proyectos() {
    this.lists = [];
    this.estados.forEach((status) => {
      if (this.search.trim() !== "") {
        this.lists.push({
          estado: status,
          tareas: this.datasource.filter((task) => {
            return (
              eval("task." + this.nombre_variable) === status.id &&
              task.nombre.toLowerCase().includes(this.search.toLowerCase())
            );
          }),
        });
      } else {
        this.lists.push({
          estado: status,
          tareas: this.datasource.filter((task) => {
            return eval("task." + this.nombre_variable) === status.id;
          }),
        });
      }
    });
    return "";
  }
  get get_id_tabla() {
    let id = this.$route.meta.id;
    if (id === undefined) {
      id = this.$route.params.id;
    }
    if (this.id_tabla > 0) {
      id = this.id_tabla;
    }
    return id;
  }
  public get estados() {
    return enumeracionModule.enumeraciones;
  }

  get get_style_height() {
    let alto = this.$vuetify.breakpoint.height;
    return "height:" + (alto - 218) + "px";
  }

  ValueOf(valor: any) {
    return UtilsString.ValueOf(valor);
  }

  onTaskDragStart(e: any) {
    e.itemData = e.fromData[e.fromIndex];
  }

  onTaskDrop(e: any) {
    e.itemData = e.fromData.tareas[e.fromIndex];

    //e.itemData.id_estado = e.toData.estado.id;
    e.itemData[this.nombre_variable] = e.toData.estado.id;

    //e.itemData.orden = e.toIndex;

    ssmHttpService
      .put(
        API.webApiBase +
          this.pantalla_modo_lista.pantalla.api_call +
          "/" +
          e.itemData.id,
        new BaseDto(e.itemData),
        false
      )
      .then(() => {
        ssmHttpService
          .get(
            API.webApiBase + this.pantalla_modo_lista.pantalla.api_call,
            null,
            false
          )
          .then((x) => (this.datasource = x));
      });
  }

  get_style() {
    let alto = this.$vuetify.breakpoint.height;
    return "max-height:" + (alto - 218) + "px";
  }

  /*getPriorityClass(task: proyecto) {
    return `priority-${task.id_prioridad}`;
  }*/

  getPriorityStyle(task: any) {
    return ` background:${
      this.estados.find((x) => x.id == eval("task." + this.nombre_variable))!
        .color
    }`;
  }

  onListReorder(e: any) {
    const list = this.lists.splice(e.fromIndex, 1)[0];
    this.lists.splice(e.toIndex, 0, list);

    const status = this.estados.splice(e.fromIndex, 1)[0];
    this.estados.splice(e.toIndex, 0, status);
  }

  nuevo() {
    this.abrir_formulario("-1");
  }

  editar(item: BaseDto) {
    this.abrir_formulario(UtilsString.ValueOf(item.id));
  }

  abrir_formulario(id_user: string) {
    this.$router.push({
      name: this.pantalla_modo_lista.pantalla.router_name,
      params: { id: id_user },
    });
  }
  refresh() {
    ssmHttpService
      .get(API.webApiBase + this.pantalla_modo_lista.pantalla.api_call)
      .then((x) => (this.datasource = x));
  }
}
